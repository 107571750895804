import './App.css';
import { AppShell, MantineProvider } from '@mantine/core';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools'
//
import Navigation from './navigation/navbar';
import { FormPage } from './pages/FormPage';
import MemberPage from './pages/memberPage';

import EventPage from './pages/EventPage';

import MainPage from './pages/mainPage';
import SuiviPage from './pages/suiviPage';
import { NavigationHead } from './navigation/header';
import { NotificationsProvider } from "@mantine/notifications";
import TeamPage from './pages/teamPage';
import { Stats } from './pages/statsPage';

import Mtheme from './theme'
import Login from './pages/login';
import WorkerPage from './worker/workerPage';
import { isLogin } from './connections/requests';
import UserPage from './pages/UserPage';
import PaymentPage from './pages/PaymentPage';
import RunPage from './pages/RunPage';
import GrantPage from './pages/GrantPage';
import JobPage from './pages/JobPage';
import AnswerPage from './pages/AnswerPage';


export const queryClient = new QueryClient()
function App() {
  return (
    <MantineProvider theme={Mtheme}>
      <QueryClientProvider client={queryClient}>
        <NotificationsProvider>
          <Router>
            <Routes>
            <Route path="" element={<Navigate to={"login"}/>} />
            <Route path='hermes/*' element={<AnswerPage/>}/>
              <Route  path="login" element={<Login/>}/>
              <Route path='/auth/*' element={isLogin()?<AppShell
                padding={0}
                navbar={<Navigation />}
                header={<NavigationHead />}
                styles={(theme) => ({
                  main: { backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.papyrus[1] },
                })}>
                  <Routes>
                    <Route exact path="" element={<MainPage />} />
                    <Route path="member/*" element={<MemberPage/>}/>
                    <Route  path="chrono/*" element={<SuiviPage/>}  />
                    <Route path="payment/*" element={<PaymentPage/>}/>
                    <Route path="grant/*" element={<GrantPage/>}/>
                    <Route path="form/*" element={<FormPage />} />
                    <Route path="event/*" element={<EventPage/>}/>
                    <Route path="run/*" element={<RunPage/>}/>
                    <Route path="job/*" element={<JobPage/>}/>
                    {/*Routes Left to Do */}
                    <Route path="team" >
                      <Route exact path='' element={<TeamPage/>}/>
                      <Route path=":worker" element={<WorkerPage/>}/>
                    </Route>
                    <Route path="stats">
                      <Route path="" element={<Stats />} />
                    </Route>
                    <Route path="user/*" element={<UserPage/>}/>
                  </Routes>
              </AppShell>:<Navigate to={"/login"}/>}/>
              </Routes>
          </Router>
          <ReactQueryDevtools />
        </NotificationsProvider>
      </QueryClientProvider>
    </MantineProvider>
  );
};

export default App;
