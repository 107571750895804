import { useState, React } from 'react';
import {
    createStyles,
    Table,
    ScrollArea,
    UnstyledButton,
    Group,
    Text,
    Center,
    TextInput,
    Button,
    Chip,
    Checkbox,
    Stack
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { keys } from '@mantine/utils';
import { IconSelector, IconChevronDown, IconChevronUp, IconSearch, IconCircleLetterX, IconCircleCheck } from '@tabler/icons-react';
import { addMemberToEvent } from '../connections/requests';


const useStyles = createStyles((theme) => ({
    th: {
        padding: '0 !important',
    },


    control: {
        width: '100%',
        padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,

        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        },
    },

    icon: {
        width: 21,
        height: 21,
        borderRadius: 21,
    },
}));





function Th({ children, reversed, sorted, onSort }) {
    const { classes } = useStyles();
    const Icon = sorted ? (reversed ? IconChevronUp : IconChevronDown) : IconSelector;
    return (
        <th className={classes.th}>
            <UnstyledButton onClick={onSort} className={classes.control}>
                <Group position="apart">
                    <Text weight={500} size="sm">
                        {children}
                    </Text>
                    <Center className={classes.icon}>
                        <Icon size={14} stroke={1.5} />
                    </Center>
                </Group>
            </UnstyledButton>
        </th>
    );
}

function filterData(data, search, filterButton) {

    const filter = filterButton;
    const query = search.toLowerCase().trim();
    return data.filter((item) =>
        keys(data[0]).some((key) => { if (typeof item[key] == "string") return item[key].toLowerCase().includes(query) })
    ).filter((item) => {
        if (filter.includes("actif") && item.actif) return true;
        if (filter.includes('inactif') && item.actif === false) return true;
        return false
    });

}

function sortData(
    data,
    payload
) {
    const { sortBy } = payload;

    if (!sortBy) {

        return filterData(data, payload.search, payload.filterButton);
    }

    return filterData(
        [...data].sort((a, b) => {
            if (payload.reversed) {
                return b[sortBy].localeCompare(a[sortBy]);
            }

            return a[sortBy].localeCompare(b[sortBy]);
        }),
        payload.search, payload.filterButton
    );
}



export function MemberTable(props) {
    let data = props.data;

    let eventID = props.id;
    const [search, setSearch] = useState('');
    const [sortedData, setSortedData] = useState(data);
    const [sortBy, setSortBy] = useState(null);
    const [reverseSortDirection, setReverseSortDirection] = useState(false);
    const [filterButton, setFilterButton] = useState(["actif", "inactif"]);
    const registrationForm = useForm({ initialValues: { event: eventID, memberToAdd: [] } })


    const setSorting = (field) => {
        const reversed = field === sortBy ? !reverseSortDirection : false;
        setReverseSortDirection(reversed);
        setSortBy(field);
        setSortedData(sortData(data, { sortBy: field, reversed, search, filterButton }));
    };
    const setFilter = (event) => {
        setFilterButton(event);
        setSortedData(sortData(data, { sortBy, reversed: reverseSortDirection, search, filterButton: event }));
    }

    const handleSearchChange = (event) => {

        const { value } = event.currentTarget;
        setSearch(value);
        setSortedData(sortData(data, { sortBy, reversed: reverseSortDirection, search: value, filterButton }));
    };


    const rows = sortedData.map((row) => (
        <tr key={row._id}>
            <td><Checkbox value={row._id} onChange={(e) => (registrationForm.values.memberToAdd.includes(row._id)) ? registrationForm.setValues({ memberToAdd: registrationForm.values.memberToAdd.filter(i => i !== row._id) }) : registrationForm.values.memberToAdd.push(row._id)} /></td>
            <td>{row.nom}</td>
            <td>{row.prenom}</td>
            <td>{row.email}</td>
            <td>{row.actif ? <IconCircleCheck color="green" /> : <IconCircleLetterX color="red" />}</td>

        </tr >
    ));
    return (
        <div>
            <ScrollArea>
                <TextInput
                    placeholder="Chercher dans tous les champs"
                    mb="md"
                    icon={<IconSearch size={14} stroke={1.5} />}
                    value={search}
                    onChange={handleSearchChange}
                />
                <Chip.Group multiple position='center' value={filterButton} onChange={setFilter}>
                    <Chip value='actif' color="green">Actif</Chip>
                    <Chip value='inactif' color="red">Inactif</Chip>
                </Chip.Group>
                <Table
                    horizontalSpacing="md"
                    verticalSpacing="xs"
                    sx={{ tableLayout: 'fixed', minWidth: 700 }}
                >
                    <thead>
                        <tr>
                            <th></th>
                            <Th
                                sorted={sortBy === 'nom'}
                                reversed={reverseSortDirection}
                                onSort={() => setSorting('nom')}
                            >
                                Nom
                            </Th>
                            <Th
                                sorted={sortBy === 'prenom'}
                                reversed={reverseSortDirection}
                                onSort={() => setSorting('prenom')}
                            >
                                Prénom
                            </Th>
                            <Th
                                sorted={sortBy === 'email'}
                                reversed={reverseSortDirection}
                                onSort={() => setSorting('email')}
                            >
                                Email
                            </Th>
                            <Th
                            >
                                Actif
                            </Th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows.length > 0 ? (
                            rows
                        ) : (
                            <tr>
                                <td colSpan={Object.keys(data[0]).length}>
                                    <Text weight={500} align="center">
                                        Nothing found
                                    </Text>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </ScrollArea>
            <Stack><Button onClick={() => {addMemberToEvent(registrationForm, eventID);props.setOpened(false);props.resetQuery()}}>Inscrire</Button></Stack>
        </div>
    );
}
