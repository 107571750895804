import { useState, React } from 'react';
import {
    createStyles,
    Table,
    ScrollArea,
    UnstyledButton,
    Group,
    Text,
    Center,
    TextInput
} from '@mantine/core';
import { keys } from '@mantine/utils';
import { IconSelector, IconChevronDown, IconChevronUp, IconSearch, IconCalendarPlus } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { fetchAllEvents } from '../connections/requests';

const useStyles = createStyles((theme) => ({
    th: {
        padding: '0 !important',
    },


    control: {
        width: '100%',
        padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,

        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        },
    },

    icon: {
        width: 21,
        height: 21,
        borderRadius: 21,
    },
}));


function Th({ children, reversed, sorted, onSort }) {
    const { classes } = useStyles();
    const Icon = sorted ? (reversed ? IconChevronUp : IconChevronDown) : IconSelector;
    return (
        <th className={classes.th}>
            <UnstyledButton onClick={onSort} className={classes.control}>
                <Group position="apart">
                    <Text weight={500} size="sm">
                        {children}
                    </Text>
                    <Center className={classes.icon}>
                        <Icon size={14} stroke={1.5} />
                    </Center>
                </Group>
            </UnstyledButton>
        </th>
    );
}



function sortData(
    data,
    payload
) {
    const { sortBy } = payload;

    if (!sortBy) {

        return data;
    }

   return data;
}


export function EventTable() {
    const {data,status} = useQuery("event",fetchAllEvents);
    const [search, setSearch] = useState('');
    const [sortedData, setSortedData] = useState(data);
    const [sortBy, setSortBy] = useState(null);
    const [reverseSortDirection, setReverseSortDirection] = useState(false);

    if(status === "loading") return <div>loading</div>
    const setSorting = (field) => {
        const reversed = field === sortBy ? !reverseSortDirection : false;
        setReverseSortDirection(reversed);
        setSortBy(field);
        setSortedData(sortData(data, { sortBy: field, reversed, search }));
    };


    const handleSearchChange = (event) => {
        const { value } = event.currentTarget;
        setSearch(value);
        setSortedData(sortData(data, { sortBy, reversed: reverseSortDirection, search: value }));
    };

    const rows =  data.map((row) => (
        <tr key={row._id}>
            <td><Link to={ row._id} style={{ textDecoration: 'none' }}>{row.title}</Link></td>
            <td>{row.location}</td>
            <td>{new Date(row.dateStart).toLocaleDateString("fr-FR")}</td>
        
        </tr >
    ));

    return (
        <div>
            <UnstyledButton component={Link} to={"/auth/form/addEvent"}>
                    <IconCalendarPlus color='green' size={32} />
                    <Text size="xs" mt={7} color="green">
                        Ajouter un Événement
                    </Text>
            </UnstyledButton>
            <ScrollArea>
                <TextInput
                    placeholder="Rechercher par nom"
                    mb="md"
                    icon={<IconSearch size={14} stroke={1.5} />}
                    value={search}
                    onChange={handleSearchChange}
                />

                <Table
                    horizontalSpacing="md"
                    verticalSpacing="xs"
                    sx={{ tableLayout: 'fixed', minWidth: 700 }}
                >
                    <thead>
                        <tr>
                            <Th
                                sorted={sortBy === 'title'}
                                reversed={reverseSortDirection}
                                onSort={() => setSorting('title')}
                            >
                                Titre
                            </Th>
                            <Th
                                sorted={sortBy === 'location'}
                                reversed={reverseSortDirection}
                                onSort={() => setSorting('location')}
                            >
                                Lieu
                            </Th>
                            <Th
                                sorted={sortBy === 'date'}
                                reversed={reverseSortDirection}
                                onSort={() => setSorting('date')}
                            >
                                Date
                            </Th>

                        </tr>
                    </thead>
                    <tbody>
                        {rows.length > 0 ? (
                            rows
                        ) : (
                            <tr>
                                <td colSpan={Object.keys(data[0]).length}>
                                    <Text weight={500} align="center">
                                        Nothing found
                                    </Text>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </ScrollArea>
        </div>
    );
}
