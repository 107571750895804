import { useState, React } from 'react';
import {
    createStyles,
    Table,
    ScrollArea,
    UnstyledButton,
    Group,
    Text,
    Center,
    TextInput,
    Chip,
    Divider,
    Pagination
} from '@mantine/core';
import { keys } from '@mantine/utils';
import { IconSelector, IconChevronDown, IconChevronUp, IconSearch, IconCircleLetterX, IconCircleCheck } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import MemberBadge from './component/MemberBadge';

const useStyles = createStyles((theme) => ({
    th: {
        padding: '0 !important',
    },
    control: {
        width: '100%',
        padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,

        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        },
    },
    icon: {
        width: 21,
        height: 21,
        borderRadius: 21,
    },
}));

function Th({ children, reversed, sorted, onSort, justify = "apart" }) {
    const { classes } = useStyles();
    const Icon = sorted ? (reversed ? IconChevronUp : IconChevronDown) : IconSelector;
    return (
        <th className={classes.th}>
            <UnstyledButton onClick={onSort} className={classes.control}>
                <Group position={justify}>
                    <Text weight={500} size="sm">
                        {children}
                    </Text>
                    <Center className={classes.icon}>
                        <Icon size={14} stroke={1.5} />
                    </Center>
                </Group>
            </UnstyledButton>
        </th>
    );
}

function filterData(data, search, filterButton) {
    const filter = filterButton;
    const query = search.toLowerCase().trim();
    return data.filter((item) =>
        keys(data[0]).some((key) => { if (typeof item[key] == "string") return item[key].toLowerCase().includes(query); return null;  })
    ).filter((item) => {
        if (filter.includes("actif") && item.active) return true;
        if (filter.includes('inactif') && item.active === false) return true;
        return false
    }).filter((item) => {
        if (filter.includes("Régulier") && item.type === 'Régulier') return true;
        if (filter.includes("Sympathisant") && item.type === 'Sympathisant') return true;
        if (filter.includes('Non-Membre') && item.type === 'Non-Membre') return true;
        if (filter.includes('Autres') && item.type === 'Autre') return true;
        if (!item.type) return true;
        return false
    });
}

function sortData(data,payload) {
    const { sortBy } = payload;
    if (!sortBy) {
        return filterData(data, payload.search, payload.filterButton);
    }
    return filterData(
        [...data].sort((a, b) => {
            if (payload.reversed) {
                return b[sortBy].localeCompare(a[sortBy]);
            }
            return a[sortBy].localeCompare(b[sortBy]);
        }),
        payload.search, payload.filterButton
    );
}

export function MemberTable(props) {
    let data = props.data
    const [activePage, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const [sortedData, setSortedData] = useState(data);
    const [sortBy, setSortBy] = useState(null);
    const [reverseSortDirection, setReverseSortDirection] = useState(false);
    const [filterButton, setFilterButton] = useState(["actif", "inactif", "Régulier", "Non-Membre", "Sympathisant", "Autres"]);

    const setSorting = (field) => {
        const reversed = field === sortBy ? !reverseSortDirection : false;
        setReverseSortDirection(reversed);
        setSortBy(field);
        setSortedData(sortData(data, { sortBy: field, reversed, search, filterButton }));
    };
    const setFilter = (event) => {
        setFilterButton(event);
        setSortedData(sortData(data, { sortBy, reversed: reverseSortDirection, search, filterButton: event }));
    };
    const handleSearchChange = (event) => {
        const { value } = event.currentTarget;
        setSearch(value);
        setSortedData(sortData(data, { sortBy , reversed: reverseSortDirection, search: value, filterButton }));
    };


    const rows = sortedData.slice((activePage-1)*12,(activePage)*12).map((row) => (
        <tr component={Link} to={ row._id} key={row._id}>
            <td align='right'>
                <Text tt={"capitalize"} ta={"right"} align='right'  component={Link} to={ row._id} style={{ textDecoration: 'none' }}>{row.nom === "" ? "aucun nom":row.nom}</Text></td>
            <td >
                <Text ta={"left"} align='left' span component={Link} to={ row._id} style={{ textDecoration: 'none', textAlign: "right" }}>{row.prenom === ""? "aucun prénom" : row.prenom}</Text></td>
            <td><Text truncate >{row.email}</Text></td>
            <td>
            {<MemberBadge type={row.type} idMembre={row.idMembre} />}
            </td>
            <td>
            {row.active ? <IconCircleCheck color="green"/> : <IconCircleLetterX color="orange"/>}
            </td>
        </tr>

    ));
    return (
        <div>

            <ScrollArea>
                <TextInput
                    placeholder="Chercher un membre"
                    mb="md"
                    icon={<IconSearch size={14} stroke={1.5} />}
                    value={search}
                    onChange={handleSearchChange}
                />
                <Chip.Group multiple position='center' value={filterButton} onChange={setFilter}>
                    <Chip value='actif' color="green">Actif</Chip>
                    <Chip value='inactif' color="orange">Inactif</Chip>
                    <Divider orientation='vertical'></Divider>
                    <Chip value='Régulier' color="blue">Régulier</Chip>
                    <Chip value='Sympathisant' color="cyan">Sympathisant</Chip>
                    <Chip value='Non-Membre' color="gray">Non-Membre</Chip>
                    <Chip value='Autres' color="red">Autres</Chip>
                </Chip.Group>
                <Table
                    horizontalSpacing="md"
                    verticalSpacing="xs"
                    sx={{ tableLayout: 'fixed', minWidth: 700 }}
                >
                    <thead>
                        <tr>
                            <Th
                                style={{ display: "flex", justifyContent: "right" }}
                                justify="right"
                                sorted={sortBy === 'nom'}
                                reversed={reverseSortDirection}
                                onSort={() => setSorting('nom')}
                            >
                                Nom
                            </Th>
                            <Th
                                style={{ display: "flex", justifyContent: "left" }}
                                justify="left"
                                sorted={sortBy === 'prenom'}
                                reversed={reverseSortDirection}
                                onSort={() => setSorting('prenom')}
                            >
                                Prénom
                            </Th>
                            <Th
                                sorted={sortBy === 'email'}
                                reversed={reverseSortDirection}
                                onSort={() => setSorting('email')}
                            >
                                Email
                            </Th>
                            <th>
                                Type
                            </th>
                            <th>
                                Actif
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows.length > 0 ? (
                            rows
                        ) : (
                            <tr>
                                <td colSpan={Object.keys(data[0]).length}>
                                    <Text weight={500} align="center">
                                        Vide
                                    </Text>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
                <Center>
                <Pagination page={activePage} onChange={setPage} total={(data.length/12+1)} />
                </Center>
            </ScrollArea>
        </div>
    );
}
