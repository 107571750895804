import { useState } from "react";
import { createStyles, Paper, Text, ThemeIcon, Group, Modal, Grid, Button, Stack,Flex,Title, Badge } from '@mantine/core';

import { IconCar, IconPhone } from '@tabler/icons-react';

const useStyles = createStyles((theme) => ({
    card: {
        position: 'relative',
        cursor: 'pointer',
        overflow: 'hidden',
        transition: 'transform 150ms ease, box-shadow 100ms ease',
        padding: theme.spacing.xl,
        paddingLeft: theme.spacing.xl * 2,

        '&:hover': {
            boxShadow: theme.shadows.md,
            transform: 'scale(1.02)',
        },

        '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            width: 6,
            backgroundImage: theme.fn.linearGradient(0, theme.colors.green[6], theme.colors.blue[6]),
        },
        '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            width: 6,
            backgroundImage: theme.fn.linearGradient(0, theme.colors.green[6], theme.colors.blue[6]),
        },
    }, cardClosed: {
        '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            width: 6,
            backgroundImage: theme.fn.linearGradient(0, theme.colors.red[4], theme.colors.orange[9]),
        },
        '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            width: 6,
            backgroundImage: theme.fn.linearGradient(0, theme.colors.red[4], theme.colors.orange[9]),
        }

    }, cardProg: {
        '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            width: 6,
            backgroundImage: theme.fn.linearGradient(0, theme.colors.orange[4], theme.colors.yellow[9]),
        },
        '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            width: 6,
            backgroundImage: theme.fn.linearGradient(0, theme.colors.orange[4], theme.colors.yellow[9]),
        }
    }

    , buttons: {
        position: "absolute",
        right: '1rem',
        top: '1rem',
    }
}));

function getIcon(type) {
    switch (type) {
        case "transport":
            return <IconCar size={28} stroke={1.5} />
        case "phone":
            return <IconPhone size={28} stroke={1.5} />

        default:
            break;
    }
};



export function PollCard({form,index}) {
    const { classes, cx } = useStyles({ status: form.filled });

    const [opened, setOpened] = useState(false);
    const [status, setStatus] = useState(form.filled);

    return (
        <>
            <Modal
                opened={opened}
                onClose={() => setOpened(false)}
                withCloseButton={false}
                size="75%"
            >
                <Grid my={"xs"}>
                    <Grid.Col span={5}>
                        <Text tt={"capitalize"} size="xl" weight={500} ta="left">
                            {form.form?.title.toString()}
                        </Text>
                    </Grid.Col>
                    <Grid.Col span={5}>
                        <Stack>
                        </Stack>
                    </Grid.Col>
                </Grid>
                {form.responses.map((r,index) => <Flex gap={"md"} align={"center"}><Title order={3}>{form.form.questions[index].title}:</Title><Text>{r}</Text></Flex>)}

            </Modal>
            <Paper id={form._id} withBorder onClick={() => { setOpened(true) }} radius="md" className={cx(classes.card, { [classes.cardClosed]: status === "closed", [classes.cardProg]: status === "inProg" })}>
                <Grid mx={"lg"}>
                    <Grid.Col span={1}>
                        <ThemeIcon
                            size="xl"
                            radius="md"
                            variant="gradient"
                            gradient={{ deg: 0, from: 'pink', to: 'orange' }}
                        >
                        </ThemeIcon>
                    </Grid.Col>
                    <Grid.Col span={9}>
                        {form.form?.title.toString()}
                    </Grid.Col>
                    <Grid.Col span={2}>
                        {form.filled ? <Badge variant="filled" color="blue">Rempli</Badge>:<Badge variant="filled" color="orange">Non Rempli</Badge>}
                        {form?.sent ? <Badge variant="filled" color="green" >Envoyé</Badge>:<Badge color="red" variant="filled">Non Envoyé</Badge>}
                    </Grid.Col>

                </Grid>
            </Paper >
        </>
    );
}