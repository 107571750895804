import { createStyles,  TextInput, Textarea, Radio, Button, Checkbox, Stack, Group } from '@mantine/core';
import {  Calendar, TimeRangeInput } from '@mantine/dates';
import 'dayjs/locale/fr-ca';
import { useForm } from '@mantine/form';
import { createOneEvent } from '../connections/requests';

const useStyles = createStyles((theme) => ({
    root: {
        position: 'relative',
    },
    input: {
        height: 'auto',
        paddingTop: 18,
    },
    label: {
        position: 'absolute',
        pointerEvents: 'none',
        fontSize: theme.fontSizes.xs,
        paddingLeft: theme.spacing.sm,
        paddingTop: theme.spacing.sm / 2,
        zIndex: 1,
    },
}));

export function AddEventForm() {
    const { classes } = useStyles();
    const form = useForm({
        initialValues: { title: "", description: "", type: "culturel" , medium:"presentiel" , location: "" , URL: "" , date: new Date() , time: [new Date(), new Date()] , send: false , limit: 0 },
        transformValues: (values) => ({
            ...values,
            dateStart: new Date(values.date.getFullYear(), values.date.getMonth(), values.date.getDay(), values.time[0].getHours(), values.time[0].getMinutes()),
            dateEnd: new Date(values.date.getFullYear(), values.date.getMonth(), values.date.getDay(), values.time[1].getHours(), values.time[1].getMinutes())
        })
    });
    return (
        <Stack p={"xl"} m={'lg'}>
            <TextInput label="Titre" placeholder="" classNames={classes} {...form.getInputProps('title')} />
            <Textarea label="Description" placeholder="" classNames={classes} {...form.getInputProps('description')} />
            <Radio.Group name="type" label="Type" {...form.getInputProps("type")}>
                <Radio color='orange' value="presentiel" label="Présentiel" />
                <Radio value="videocall" label="Video Conference" />
            </Radio.Group>
            <Radio.Group name="medium" label="Medium" {...form.getInputProps("medium")}>
                <Radio color='orange' value="culturel" label="Culturel" />
                <Radio value="reunion" label="Réunion" />
            </Radio.Group>
            {(form.values.type === "presentiel") ? <TextInput label="Adresse Civic" placeholder="" classNames={classes} {...form.getInputProps('location')} /> : <TextInput label="Lien de la VidéoConférence" placeholder="" classNames={classes} {...form.getInputProps('location')} />}
            <Group>
                <Calendar value={form.values.date} {...form.getInputProps("date")} />
                <TimeRangeInput label="Temps de l'événement" {...form.getInputProps("time")} />
            </Group>
            <TextInput label="Limite d'inscription" {...form.getInputProps("limit")} />
            <Checkbox label="Notifier les membres" {...form.getInputProps("send")} />
            <Button onClick={() => createOneEvent(form)}>Créer</Button>
        </Stack >
    );
}