import React from 'react'
import { useQuery } from 'react-query'
import { fillAnswerForm, getAnswerForm } from '../connections/requests'
import { Button, Card, Container,Radio,Text, Textarea, useMantineTheme,Title, Center, Checkbox, Stack, Flex, Group, Grid } from '@mantine/core'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm } from '@mantine/form'

export default function AnswerPage() {
  const navigate = useNavigate();
  const theme = useMantineTheme();
    const {"*":formID} = useParams();
    const answerQuery = useQuery(["form",formID],getAnswerForm)
    const form = useForm({initialValues:{responses:[]},transformValues: (values) =>{return {...answerQuery.data,...values}}})
    if(answerQuery.status==="success"){
      if(answerQuery.data.filled === false)
      return (
    <div style={{background:theme.colors.papyrus[0],height:'100vh'}}>
      <form onSubmit={form.onSubmit((values)=>{fillAnswerForm(values);navigate("#")})}>
        <Container p='lg'>
          <Card m={"lg"} p={"xl"} shadow='xl' radius={"lg"}>
            <Card.Section bg={theme.colors.blue} p={"lg"}>
              <Title color='white' ta={"center"} order={2}>{answerQuery.data.form?.title}</Title>
            </Card.Section>
            <Card.Section>
              <div style={{margin:"2rem"}} dangerouslySetInnerHTML={{__html:answerQuery.data.form?.message}}></div>
            </Card.Section>
            <Card.Section p={"lg"}>
              <Stack>
              {answerQuery.data.form.questions?.map((q,index) => {return <QuestionCard  index={index} form={form} question={q}/>})}
              </Stack>
            </Card.Section>
            <Card.Section>
              <Center p={"lg"}>
                <Button type='submit'>Soumettre</Button>
              </Center>
            </Card.Section>
          </Card>
        </Container>
      </form>
    </div>
      )
      return (
        <div style={{background:theme.colors.papyrus[0],height:'100vh'}}>
          <Container>
            <Center>
              <Text m={"xl"}>Ce formulaire a déjà été répondu</Text>
            </Center>
          </Container>
        </div>
      )
    }
}

function QuestionCard({index,form,question}){

  if(question.type==="text")return(
    <>
      <Text>{question.title}</Text>
      <Textarea {...form.getInputProps(`responses.${index}`)}/>
    </>
  )
  if(question.type==="choice" && question.multiple) return (
    <>
    <Text>{question.title}</Text>
      <Checkbox.Group {...form.getInputProps(`responses.${index}`)}>
        {question.choices?.map(b => <Checkbox label={b} value={b} />)}
      </Checkbox.Group>
    </>
  )
  if(question.type==="choice" && !question.multiple) return (
    <>
    <Text>{question.title}</Text>
      <Radio.Group {...form.getInputProps(`responses.${index}`)}>
        {question.choices?.map(b => <Radio label={b} value={b} />)}
      </Radio.Group>
    </>
  )
  if(question.type === "bool") return(
    <>
      <Text>{question.title}</Text>
      <Checkbox/>
    </>
  )
}