import {  TextInput, Textarea,  Button, Text,   Grid, Stack, NumberInput,  Modal, Table, UnstyledButton } from '@mantine/core';
import { useState } from "react"
import { DatePicker } from '@mantine/dates';
import 'dayjs/locale/fr-ca';
import { useForm } from '@mantine/form';
import { useDebouncedValue } from '@mantine/hooks';
import {  useQuery } from 'react-query';
import { createOneRun, fetchAllMembers, fetchAllWorkers } from '../connections/requests';
import TimeSlot from './components/TimeSlot';




export function AddTimeslotForm() {
    const [openedWorker, setOpenedWorker] = useState(false);
    const [openedMember, setOpenedMember] = useState(false);
    const [workerName,setWorkerName] = useState("");
    const [memberName,setMemberName] = useState("");
    const [search, setSearch] = useState("");
    const [debouncedSearch] = useDebouncedValue(search, 200)
    const [searchW, setSearchW] = useState("");
    const [debouncedSearchW] = useDebouncedValue(searchW, 200)
    const workerQuery = useQuery(['workers', debouncedSearchW], fetchAllWorkers);
    const memberQuery = useQuery(['members', debouncedSearch], fetchAllMembers);

    const form = useForm({
        initialValues: { worker: "", member: "", locations: [], distance: 0, hour: 0, minute: 0, description: "",timeStamp: new Date() }
    })
    return (
        <>
            <Modal opened={openedWorker} onClose={() => setOpenedWorker(false)} title="Recherche Membre">
                <TextInput
                    label="Inscrire le nom ou prénom"
                    value={searchW}
                    style={{ flex: 1 }}
                    onChange={(event) => setSearchW(event.currentTarget.value)}
                />
                {(workerQuery.status === "success" && workerQuery.data.length > 0) ? workerQuery.data.filter(e => (new RegExp(debouncedSearchW,'i').test(e.prenom)|| new RegExp(debouncedSearchW,'i').test(e.nom)) ).map(i => <Button color="dark" m={"xl"} key={i._id} onClick={(e) => { form.setValues({ worker: i._id }); setOpenedWorker(false);setWorkerName(`${i.prenom} ${i.nom}`) }}><Text>{`${i.prenom} ${i.nom}`}</Text></Button>) : <Text my={"sm"} ta={"center"}>vide </Text>}
            </Modal>
            <Modal opened={openedMember} onClose={() => setOpenedMember(false)} title="Recherche Membre">
                    <TextInput
                        label="Inscrire le nom ou prénom"
                        value={search}
                        style={{ flex: 1 }}
                        onChange={(event) => setSearch(event.currentTarget.value)}
                    />
                    {(memberQuery.status === "success") ? 
                    <Table>
                    <tbody>
                    {memberQuery.data.filter(e => (new RegExp(debouncedSearch,'i').test(e.prenom)|| new RegExp(debouncedSearch,'i').test(e.nom)) ).map(i => <tr color="dark" m={"xl"} key={i._id} ><td><UnstyledButton onClick={(e) => { form.setValues({ member: i._id}); setOpenedMember(false);setMemberName(`${i.prenom} ${i.nom}`) }}>{`${i.prenom} ${i.nom}`}</UnstyledButton></td></tr>) }
                    </tbody>
                    </Table>
                    : <Text my={"sm"} ta={"center"}>vide</Text>}
            </Modal>
            <Stack p={"xl"} m={'lg'}>
                <Grid>
                    <Grid.Col span={9}>
                        <TimeSlot/>
                        <Textarea my={"xl"} label="Description" placeholder="" {...form.getInputProps('description')} />
                    </Grid.Col>
                    <Grid.Col span={2}>
                        <Stack>
                        <NumberInput precision={2} step={0.25} defaultValue={0} formatter={(value) =>!Number.isNaN(parseFloat(value)) ? `${value} km`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ','): 'kilomètre '}  label="Distance" {...form.getInputProps('distance')}/>
                        <DatePicker label="Date" {...form.getInputProps("timeStamp")}/>
                        </Stack>
                    </Grid.Col>
                </Grid>
                <Button onClick={() => {createOneRun(form);form.reset()}}>Créer</Button>
            </Stack >
        </>
    );
}