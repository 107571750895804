import { useState,React } from "react";
import {  Paper, Text, ThemeIcon, Group,  Grid, Button, Stack,Container } from '@mantine/core';
import { RichTextEditor } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import SubScript from '@tiptap/extension-subscript';
import { IconCar, IconPhone } from '@tabler/icons-react';
import { useQuery,useMutation } from 'react-query';
import { Link,useParams } from "react-router-dom";
import FollowUp from "./followUp";
import {closeChrono,inProgChrono,postFollowUp,fetchChronoByID} from "../connections/requests";
const parse = require('html-react-parser');


function getIcon(type) {
    switch (type) {
        case "transport":
            return <IconCar size={28} stroke={1.5} />
        case "phone":
            return <IconPhone size={28} stroke={1.5} />

        default:
            break;
    }
};
let content = "";

export default function ChronoPage() {
    let { suivi } = useParams();
 const {data,status} = useQuery(['chronos',suivi],fetchChronoByID);
 const mutation = useMutation((data) => postFollowUp(data));
 const closeMutation = useMutation((id) => closeChrono(id, setStatus("closed")));
 const progMutation = useMutation((id) => inProgChrono(id, setStatus("inProg")));
 const [opened, setOpened] = useState(false);
 const [sstatus, setStatus] = useState("closed");
 const editor = useEditor({
    extensions: [
        StarterKit,
        Underline,
        Link,
        Superscript,
        SubScript,
        Highlight,
        TextAlign.configure({ types: ['heading', 'paragraph'] }),
    ],
    content,
});
if(status === "success")
    return (
    <Container bg={"white"} p={"xl"} m={"xl"} size={"xl"}>
        <Grid my={"xs"}>
                    <Grid.Col span={1}>
                        <ThemeIcon
                            size="xl"
                            radius="md"
                            variant="gradient"
                            gradient={{ deg: 0, from: 'pink', to: 'orange' }}
                        >
                            {getIcon(data.type)}
                        </ThemeIcon>
                    </Grid.Col>
                    <Grid.Col span={5}>
                        <Text tt={"capitalize"} size="xl" weight={500} ta="left">
                            {data.titre}
                        </Text>
                    </Grid.Col>
                    <Grid.Col span={5}>
                        <Stack>
                            <Text size="md" ta="center">Travailleur : {data?.worker?.prenom +" "+data?.worker?.nom}</Text>
                            <Text size={"md"} ta="center">Membre : {data?.member?.fullName}</Text>
                        </Stack>
                    </Grid.Col>
                </Grid>
                <Text weight={"bolder"}>Description : </Text>
                <Paper mb={"md"} shadow="sm" p="xs" withBorder>
                    {parse(data.description)}
                </Paper>
                <Text weight={"bolder"}>Suivis : </Text>
                <Paper my={"md"} shadow="sm" p="xs" withBorder>
                    {(data.followUp.length > 0) ? data.followUp.map(i => <FollowUp suivi={i} />) : <Text> Aucun Suivi</Text>}
                </Paper>
                <Text weight={"bolder"}>Ajouter un suivi : </Text>
                <RichTextEditor my={"lg"} py={"sm"} editor={editor}>
                    <RichTextEditor.Toolbar sticky stickyOffset={60}>
                        <RichTextEditor.ControlsGroup>
                            <RichTextEditor.Bold />
                            <RichTextEditor.Italic />
                            <RichTextEditor.Underline />
                            <RichTextEditor.Strikethrough />
                            <RichTextEditor.ClearFormatting />
                            <RichTextEditor.Highlight />
                            <RichTextEditor.Code />
                        </RichTextEditor.ControlsGroup>
                        <RichTextEditor.ControlsGroup>
                            <RichTextEditor.H1 />
                            <RichTextEditor.H2 />
                            <RichTextEditor.H3 />
                            <RichTextEditor.H4 />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                            <RichTextEditor.Blockquote />
                            <RichTextEditor.Hr />
                            <RichTextEditor.BulletList />
                            <RichTextEditor.OrderedList />
                            <RichTextEditor.Subscript />
                            <RichTextEditor.Superscript />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                            <RichTextEditor.Link />
                            <RichTextEditor.Unlink />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                            <RichTextEditor.AlignLeft />
                            <RichTextEditor.AlignCenter />
                            <RichTextEditor.AlignJustify />
                            <RichTextEditor.AlignRight />
                        </RichTextEditor.ControlsGroup>
                    </RichTextEditor.Toolbar>
                    <RichTextEditor.Content />

                </RichTextEditor>

                <Group position="center" spacing="xl" grow>
                    <Button onClick={() => mutation.mutate({ id: data._id, followUps: data.followUp, newFollowUp: editor.getHTML() })}>Ajouter Suivi</Button>
                    <Button color="orange" onClick={() => { progMutation.mutate(data._id); setOpened(false) }}>Mettre en attente</Button>
                    <Button color="red" onClick={() => { closeMutation.mutate(data._id); setOpened(false) }}>Fermer la Chrono</Button>
                </Group>
            </Container>
)
return "loading"
}
