import React from 'react'
import { useQuery } from 'react-query'
import { fetchAllMembers } from '../connections/requests'
import { useForm } from '@mantine/form';
import { TextInput,Container,Card,Paper } from '@mantine/core';
import { MemberTable } from '../member/memberRegistrationForm';

export default function AddList() {
    const memberQuery = useQuery(["list"],fetchAllMembers);
    const form = useForm(
        {initialValues:{title:"",type:"member",list:[]}}
    )
    if(memberQuery.status==="success")
  return (
    <form>
      <TextInput label={"Titre"} {...form.getInputProps("title")}/>
      <Container p={"lg"}>
        <Paper>
        <MemberTable {...memberQuery} />
        </Paper>
      </Container>
    </form>
  )
}
