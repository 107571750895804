import 'dayjs/locale/fr-ca';
import React from 'react'
import { useQuery } from 'react-query';
import {  Stack, Text,  Card,Title, Divider, useMantineTheme,Flex } from '@mantine/core';
import { fetchMemberSameAddress } from '../connections/requests';
import { Link } from 'react-router-dom';

export default function MemberNote(props) {
    const {notes,id} = props.props;
    const memberQuery = useQuery(['members',id,"sameAdd"], fetchMemberSameAddress);
    const theme = useMantineTheme();
    return (
        <Card
        radius="md"
        withBorder
        p="lg"
        sx={(theme) => ({
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
        })}
    >
        <Card.Section bg={theme.colors.blue} p={"sm"}>
                <Title c={"white"} ta={"center"} order={3}>Notes</Title>
            </Card.Section>
            <Card.Section>
                <Stack m={"lg"}>
                    {notes?.map(e => <><Text >{e}</Text><Divider/></>)}
                </Stack>
            </Card.Section>
            <Card.Section>
                <Flex gap={"md"} m={"lg"}>
                <Text>Cohabitation :</Text>
                {(memberQuery.status ==="success" && memberQuery.data) ? memberQuery.data.map( e => <Link to={`/auth/member/${e?.id}`}> {` ${e?.prenom}  ${e?.nom} `} </Link>):null}
                </Flex>
            </Card.Section>
        </Card>
    )
}
