import React, { useState } from 'react'

import {PaymentCard} from "./components/PaymentCard";
import { useQuery, } from 'react-query';
import { Text, Stack,  Chip,} from '@mantine/core';
import { fetchPaymentsByMemberID } from '../connections/requests';



export function PaymentList(props) {
    const membreId = props.membre;
    const { data, status } = useQuery(['payment', membreId], fetchPaymentsByMemberID);
    const [filter, setValue] = useState(["received", "waiting", "canceled"]);
    return (
        <>
            <Chip.Group p={"xs"} position='center' align='center' multiple value={filter} onChange={setValue}>
                <Chip value={'received'} color='green'>Reçus</Chip>
                <Chip value={'waiting'} color='orange'>En attente</Chip>
                <Chip value={'canceled'} color='red'>Annulés</Chip>
            </Chip.Group>
            <Stack>
                {status === "success" && data.payments.length>0 ? data.payments.filter(i => filter.includes(i.status)).map((i) => (<PaymentCard props={i} key={i._id} />)) : <Text>No Data</Text>}
            </Stack>
        </>
    )
}
