import React, { useState } from 'react'
import { Button, Center, Container, Flex, Grid, Modal, Stack, Tabs, Title } from '@mantine/core';
import { MemberCard } from './memberCard';
import { MemberInfo } from './memberInfo';
import { Chrono } from './chrono';
import { ChronoList } from './chronoList';
import MemberInfoEdit from './memberInfoEdit';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import MemberPayment from './MemberPayment';
import { fetchMemberByID,deleteMemberByID, createOneWorkerFromMember } from '../connections/requests';
import { PaymentList } from '../payment/PaymentList';
import MemberNote from './MemberNote';
import { MemberTraits } from './MemberTraits';
import { PollList } from './PollList';

export default function Member() {
    let redirect = useNavigate();
    let { membre } = useParams();
    const [openedChrono, setOpenedChrono] = useState(false);
    const [openedEdit, setOpenedEdit] = useState(false);
    const [openedDelete, setOpenedDelete] = useState(false);
    const [openedPayment, setOpenedPayment] = useState(false);
    const [openedNote, setOpenedNote] = useState(false);
    const [openedVolunteer,setOpenedVolunteer]= useState(false);
    const { data, status } = useQuery(['members', membre], fetchMemberByID);
    if (status === "success" && data._id !== undefined)
        return (
            <Container my="md">
                <Flex my={"md"} gap={"md"} justify={"flex-start"}>
                    <Button color='orange' onClick={() => setOpenedChrono(true)}>Ajouter Chrono</Button>
                    <Button onClick={() => setOpenedEdit(true)}>Modifier le membre</Button>
                    <Button color='red' onClick={() => setOpenedDelete(true)}>Supprimer le Membre</Button>
                    <Button color='violet' onClick={()=> setOpenedVolunteer(true)}>Ajouter Bénévole</Button>
                </Flex>
                <Modal
                    opened={openedChrono}
                    onClose={() => setOpenedChrono(false)}
                    size="75%"
                >
                    <Chrono memberId={membre} modal={setOpenedChrono} />
                </Modal>
                <Modal
                    opened={openedEdit}
                    onClose={() => setOpenedEdit(false)}
                    size={"75%"}
                >
                    <MemberInfoEdit member={data} modal={setOpenedEdit} />
                </Modal>
                <Modal 
                opened={openedVolunteer}
                onClose={()=>setOpenedVolunteer(false)}
                withCloseButton={false}
                centered
                >
                    <Center>
                        <Stack>
                        <Title order={3}>Confirmer la création du bénévole</Title>
                        <Button onClick={()=> {createOneWorkerFromMember(membre);setOpenedVolunteer(false)}} color='red'>Confirmer</Button>
                        </Stack>
                    </Center>
                </Modal>
                <Modal 
                    opened={openedDelete}
                    onClose={() => setOpenedDelete(false)}
                    withCloseButton={false}
                    centered
                >
                <Flex justify={"center"}><Button color='red' onClick={()=> {deleteMemberByID(membre);redirect("/auth/member");}}>Confirmer la suppression</Button></Flex>
                </Modal>
                <Modal opened={openedNote} onClose={()=>setOpenedNote(false)}>
                <MemberPayment member={data} modal={setOpenedNote}/>
                </Modal>
                <Modal opened={openedPayment} onClose={()=>setOpenedPayment(false)}>
                <MemberPayment member={data} modal={setOpenedPayment}/>
                </Modal>
                <Grid gutter="md">
                    <Grid.Col span={6}>
                        <MemberCard props={data} />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <MemberInfo props={data} />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <MemberNote props={data}/>
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <MemberTraits props={data}/>
                    </Grid.Col>
                </Grid>
                <Tabs defaultValue="chrono">
                    <Tabs.List>
                        <Tabs.Tab value="chrono">Suivis</Tabs.Tab>
                        <Tabs.Tab value="payment">Paiements</Tabs.Tab>
                        <Tabs.Tab value="poll">Questionnaires</Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel value="chrono" pt="xs">
                        <ChronoList membre={data._id} />
                    </Tabs.Panel>
                    <Tabs.Panel value="payment" pt="xs">
                        <PaymentList membre={data._id}/>
                    </Tabs.Panel>
                    <Tabs.Panel value="poll" pt="xs">
                        <PollList membre={data._id}/>
                    </Tabs.Panel>
                </Tabs>
            </Container>
        );
}
