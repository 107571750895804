import { useState, React } from 'react';
import {
    createStyles,
    Table,
    ScrollArea,
    UnstyledButton,
    Group,
    Text,
    Center,
    Card,
    TextInput,
    Paper,
    Modal,
    Button,
    Title,
    Flex,
    Tabs
} from '@mantine/core';
import { IconSelector, IconChevronDown, IconChevronUp, IconSearch, IconCalendarPlus } from '@tabler/icons-react';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import {  fetchAllMembers, getAnswerList } from '../connections/requests';
import { SingleAnswerTable } from './SingleAnswerTable';
import AnswerHeader from './AnswerHeader';
import FormStatusChip from './components/FormStatusChip';
import { IconUserPlus } from '@tabler/icons-react';
import { AddRespondant } from './components/AddRespondant';


const useStyles = createStyles((theme) => ({
    th: {
        padding: '0 !important',
    },

    control: {
        width: '100%',
        padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,

        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        },
    },

    icon: {
        width: 21,
        height: 21,
        borderRadius: 21,
    },
}));


function Th({ children, reversed, sorted, onSort }) {
    const { classes } = useStyles();
    const Icon = sorted ? (reversed ? IconChevronUp : IconChevronDown) : IconSelector;
    return (
        <th className={classes.th}>
            <UnstyledButton onClick={onSort} className={classes.control}>
                <Group position="apart">
                    <Text weight={500} size="sm">
                        {children}
                    </Text>
                    <Center className={classes.icon}>
                        <Icon size={14} stroke={1.5} />
                    </Center>
                </Group>
            </UnstyledButton>
        </th>
    );
}



function sortData(
    data,
    payload
) {
    const { sortBy } = payload;

    if (!sortBy) {

        return data;
    }

   return data;
}


export function AnswerTable() {
    let {id} = useParams();
    const {data,status,refetch} = useQuery(['form',id],getAnswerList);
    const memberQuery = useQuery("member",fetchAllMembers);
    const [search, setSearch] = useState('');
    const [sortedData, setSortedData] = useState(data);
    const [sortBy, setSortBy] = useState(null);
    const [answerModal,setOpenedModal] = useState(-1);
    const [respondantModal,setOpenedRespondant] = useState(false);
    const [reverseSortDirection, setReverseSortDirection] = useState(false);

    if(status === "loading") return <div>loading</div>
    const setSorting = (field) => {
        const reversed = field === sortBy ? !reverseSortDirection : false;
        setReverseSortDirection(reversed);
        setSortBy(field);
        setSortedData(sortData(data, { sortBy: field, reversed, search }));
    };

    const handleSearchChange = (event) => {
        const { value } = event.currentTarget;
        setSearch(value);
        setSortedData(sortData(data, { sortBy, reversed: reverseSortDirection, search: value }));
    };

    const rows = data.responses.length>=1 ? data.responses.map((row,index) => (
        <tr key={row._id}>
            <td><Link to={ "/auth/member/"+row._id} style={{ textDecoration: 'none' }}>{row?.member?.fullName ? row?.member?.fullName : row._id}</Link></td>
            <td>{row.filled ?<Button  onClick={()=> setOpenedModal(index)}>Réponses</Button>:<Button disabled>Réponses</Button>}</td>
            <td><FormStatusChip filled={row.filled}/></td>
            <Modal size={"xl"} title="Réponses" opened={answerModal === index} onClose={()=>setOpenedModal(-1)}>
                {row.responses.map((r,index) => <Flex gap={"md"} align={"center"}><Title order={4}>{data.questions[index].title}:</Title><Text>{r}</Text></Flex>)}
            </Modal>
        </tr >
    )):[<tr></tr>];

    return (
        <div>
            <UnstyledButton  onClick={()=>setOpenedRespondant(true)}>
                    <IconUserPlus color='green' size={32} />
                    <Text size="xs" mt={7} color="green">
                        Ajouter un répondant
                    </Text>
            </UnstyledButton>
            <Modal
            size={"70%"}
            opened={respondantModal}
            onClose={()=>setOpenedRespondant(false)}>
            <AddRespondant data={memberQuery.data}id={id} setOpened={()=>setOpenedRespondant(false)} queryReset={refetch} />
            </Modal>
            <Tabs defaultValue={"all"}>
                <Tabs.List>
                <Tabs.Tab value='all' >Ensemble</Tabs.Tab>
                    {data.questions.map((q,i) => <Tabs.Tab value={`${i}`}>{q.title}</Tabs.Tab> )}
                </Tabs.List>
            <Tabs.Panel value='all'>
            <ScrollArea>
                <Paper p={"md"}>
                    <Card>
                        <Title order={3}>{data?.title.toString()}</Title>
                    </Card>
                <Table
                    horizontalSpacing="md"
                    verticalSpacing="xs"
                    sx={{ tableLayout: 'fixed', minWidth: 700 }}
                >
                    <thead>
                        <tr>
                            <Th
                                sorted={sortBy === 'title'}
                                reversed={reverseSortDirection}
                                onSort={() => setSorting('title')}
                            >
                                Répondant
                            </Th>
                            <th
                                sorted={sortBy === 'location'}
                                reversed={reverseSortDirection}
                                onSort={() => setSorting('event')}
                            >
                                Réponses
                            </th>
                            <Th
                                sorted={sortBy === 'date'}
                                reversed={reverseSortDirection}
                                onSort={() => setSorting('date')}
                            >
                                État
                            </Th>

                        </tr>
                    </thead>
                    <tbody>
                        {rows.length > 0 ? (
                            rows
                        ) : (
                            <tr>
                                <td colSpan={Object.keys(data[0]).length}>
                                    <Text weight={500} align="center">
                                        Nothing found
                                    </Text>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
                </Paper>
            </ScrollArea>
            </Tabs.Panel>
            {data.questions.map((q,i)=> <Tabs.Panel value={`${i}`}><SingleAnswerTable questionIndex={i}/></Tabs.Panel>)}
            </Tabs>
        </div>
    );
}
