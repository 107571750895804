import React, { useState } from 'react'

import { ChronoCard } from './chronoCardModal'
import { useQuery, } from 'react-query';
import { Text, Stack,  Chip,} from '@mantine/core';
import { getAnswersByMemberId } from '../connections/requests';
import { PollCard } from './PollCard';




export function PollList(props) {

    const membreId = props.membre;
    const { data, status } = useQuery(['poll', membreId], getAnswersByMemberId);
    const [filter, setValue] = useState(["open", "inProg", "closed"]);

    return (
        <>
            <Chip.Group p={"xs"} position='center' align='center' multiple value={filter} onChange={setValue}>
                <Chip value={'open'} color='green'>Ouverte</Chip>
                <Chip value={'inProg'} color='orange'>En attente</Chip>
                <Chip value={'closed'} color='red'>Fermée</Chip>
            </Chip.Group>

            <Stack>
                {status === "success" ? data.map((i, index) => (<PollCard form={i} key={i._id} />)) : <Text>No Data</Text>}
            </Stack>
        </>
    )
}
