import React, { useState } from 'react'
import { Button, Container, Grid, Modal, Text, Paper, Card, Title, ColorSwatch, Flex, Group,Table } from '@mantine/core';

import { useQuery } from 'react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { MemberTable } from '../member/memberRegistrationForm';
import EventMemberRow from './eventMemberRow';
import { IconBrandZoom, IconUsers } from '@tabler/icons-react';
import EventInfoEdit from './eventInfoEdit';
import { fetchAllMembers,fetchEventByID,deleteEventByID, getAnswerList } from '../connections/requests';
import AddForm from '../forms/AddForm';



function getEmail(members){
    let emailString = "";
    if(members.length === 1) return members[0]._id.email;
    for(let i = 0;i<members.length;i++){
        if(members[i]._id?.email) 
        emailString += members[i]._id.email+" ;";
    }
    return emailString;

}

export default function Event() {
    let navigate = useNavigate();
    let { event } = useParams();
    const [opened, setOpened] = useState(false);
    const [openedChrono, setOpenedChrono] = useState(false);
    const [openedEdit, setOpenedEdit] = useState(false);
    const [openedDelete, setOpenedDelete] = useState(false);
    const [openedForm,setOpenedForm] = useState(false)
    const { data, status } = useQuery(['events', event], fetchEventByID);
    const memberQuery = useQuery("members", fetchAllMembers);

    if (status === "success"){
        
        return (
            <Container my="md">
                <Modal size="70%" opened={opened} onClose={() => setOpened(false)} title="Recherche Membre">
                    <Container>
                        <MemberTable id={event} data={memberQuery.data} members={data.members} setOpened={setOpened} queryReset={()=>memberQuery.refetch()}  />
                    </Container>
                </Modal>
                <Modal
                    opened={openedChrono}
                    onClose={() => setOpenedChrono(false)}
                    size="75%"
                >
                </Modal>
                <Modal
                    opened={openedEdit}
                    onClose={() => setOpenedEdit(false)}
                    size={"75%"}
                ><EventInfoEdit event={data} modal={setOpenedEdit} />
                </Modal>
                <Modal opened={openedDelete}
                    onClose={() => setOpenedDelete(false)}
                >
                    <Button color='red' onClick={() => { deleteEventByID(event).then( () => {navigate("/auth/event")}) }}> CONFIRMER LA SUPPRESSION</Button>
                </Modal>
                <Modal
                    opened={openedForm}
                    size={"70%"}
                    onClose={()=> setOpenedForm(false)}
                >
                <AddForm event={event} onComplete={()=>setOpenedForm(false)} />
                </Modal>
                <Flex my={"md"} gap={"md"} justify={"flex-start"}>
                    <Button color='orange' onClick={() => setOpenedChrono(true)}>Ajouter Chrono</Button>
                    <Button onClick={() => setOpenedEdit(true)}>Modifier l'événement</Button>
                    <Button color='red' onClick={() => setOpenedDelete(true)}>Supprimer l'événement</Button>
                    <Button disabled={data.form}  color='lime' onClick={()=> setOpenedForm(true)}>Ajouter un formulaire</Button>
                </Flex>
                <Grid gutter="md">
                    <Grid.Col span={6}>
                        <Card height={200}>
                            <Grid>
                                <Grid.Col span={10}>
                                    <Title order={3} mx={"xl"} weight={700}>
                                        {(!data.title) ? "Aucun Titre" : data.title}
                                    </Title>
                                </Grid.Col>
                                <Grid.Col span={2}>
                                    {data.type === "presentiel" ? <ColorSwatch withShadow color='orange' size={"2.5rem"}><IconUsers color='white' /></ColorSwatch> : <ColorSwatch withShadow color='blue' size={"2.5rem"}><IconBrandZoom color='white' /></ColorSwatch>}
                                </Grid.Col>
                            </Grid>
                            <Paper my={"md"} withBorder>
                                <Text ta={"left"} m={10} >
                                    {(!data.description) ? "Aucune Description" : data.description}
                                </Text>
                            </Paper>
                        </Card>
                    </Grid.Col >
                    <Grid.Col span={6}>
                        <Card shadow='sm' radius='md' withBorder>
                            <Card.Section withBorder inheritPadding py="md" ><Title order={3} ta='center'>Information de l'activité</Title></Card.Section>
                            <Card.Section inheritPadding py="md">
                                <Text>{`Date de l'activité : ${new Date(data.dateStart).toLocaleDateString('fr-ca')} `}</Text>
                                <Text>{`Temps de l'activité : ${new Date(data.dateStart).toLocaleTimeString('fr-ca', { hour: "2-digit", minute: "2-digit" })} - ${new Date(data.dateEnd).toLocaleTimeString('fr-ca', { hour: "2-digit", minute: "2-digit" })} `}</Text>
                                <Text>{data.montant ? data.montant : "Aucun montant à l'activité"}</Text>
                            </Card.Section>
                        </Card>
                    </Grid.Col>
                    <Grid.Col>
                        <Paper p={"md"}>
                            <Group>
                                <Button onClick={()=>{window.open("mailto:?bcc="+getEmail(data.members), '_blank')}}>Courriels</Button>
                                <Button component={Link} to={"/auth/form/answers/"+data.form} onClick={()=>{}}>Réponse</Button>
                            </Group>
                        </Paper>
                    </Grid.Col>
                    <Grid.Col span={12}>
                        {(data.members === undefined) ? "Aucun membre" :
                            data.members.map((i,index) => <EventMemberRow member={i._id} paid={i.paid} key={i._id._id} event={event} form={data?.form} />)
                        }
                        <Button m="xl" onClick={(e) => { setOpened(true) }}>Ajouter Membre</Button>
                    </Grid.Col>
                            
                </Grid>
            </Container>
        );
    }
}
function Form({data}){
    const formQuery = useQuery(["form",data.form],getAnswerList);
    if(formQuery.status==="success")return(
    <>
    <Table>
        <thead>
            <th>Membre</th>
            {formQuery.data.questions.map((m) => {return <th>{m.title}</th>})}
        </thead>
        <tbody>
            {formQuery.data.responses.map((m) => {return <tr>{m.responses.map((i)=><td>{i}</td>)}</tr>})}
        </tbody>
    </Table>
    </>
    )
}
