import React from 'react'
import {Badge} from "@mantine/core";

function getTypeChip(type,idMembre) {
    if (type === "Régulier") return <Badge color='blue' variant='outline'>{idMembre}</Badge>
    if (type === "Non-Membre") return <Badge color='gray' variant='outline'>Non-Membre</Badge>
    if (type === "Sympathisant") return <Badge color='cyan' variant='outline'>Sympathisant</Badge>
    if (type === "Autres") return <Badge color='red' variant='outline'>Autres</Badge>
    else return <Badge color='red' variant='outline'>ERREUR</Badge>
}

export default function MemberBadge(props) {
    const {type,idMembre} = props;
    console.log(idMembre)
  return (
    getTypeChip(type,idMembre)
  )
}
