import React,{useState} from 'react'
import { Button, Checkbox, Table, NumberInput, Paper,Radio,  Textarea, TextInput,Flex,Text, Group, Stepper,Center } from '@mantine/core';
import { useForm } from '@mantine/form'
import { DatePicker } from '@mantine/dates';
import { RichTextEditor } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import Link from "@tiptap/extension-link"
import SubScript from '@tiptap/extension-subscript';
import { createForm } from '../connections/requests';
import { useNavigate } from 'react-router-dom';

let questionsInit = [{type:"",title:"",choices:[]},{type:"",title:"",choices:[]},{type:"",title:"",choices:[]},{type:"",title:"",choices:[]},{type:"",title:"",choices:[]},{type:"",title:"",choices:[]},{type:"",title:"",choices:[]},{type:"",title:"",choices:[]},{type:"",title:"",choices:[]},{type:"",title:"",choices:[]},{type:"",title:"",choices:[]},{type:"",title:"",choices:[]},{type:"",title:"",choices:[]},{type:"",title:"",choices:[]}]
let content = ""



export default function AddForm({event,onComplete}) {
    const form = useForm({
        initialValues: {distributionList : [],distributionDate: new Date(),questions:questionsInit,deadline:new Date(),event:event,title:"",message:""},
        transformValues:(values)=>({
            ...values,
            message:editor.getHTML(),
            questions: values.questions.slice(0,amountQuestions)
        })

    });
    const navigate =useNavigate()
    const editor = useEditor({
        extensions: [
            StarterKit,
            Underline,
            Link,
            Superscript,
            SubScript,
            Highlight,
            TextAlign.configure({ types: ['heading', 'paragraph'] }),
        ],
        content,
    });
    const [amountQuestions,setAmountQuestions] = useState(1)    
    const [active, setActive] = useState(0);
    const nextStep = () => setActive((current) => (current < 2 ? current + 1 : current));
    const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));
  
    if(!onComplete) onComplete = () => navigate("/auth/form/answers")
    return (
            <form onSubmit={form.onSubmit((values)=>{createForm(values);onComplete()})}>
                <Stepper active={active} onStepClick={setActive}>
                    <Stepper.Step label="Information du formulaire">
                        <Group grow position='center' >
                            <DatePicker label="Date d'envoi" dropdownType='modal' {...form.getInputProps("distributionDate")} minDate={new Date()}/>
                            <DatePicker label="Date limite" dropdownType='modal'{...form.getInputProps("deadline")} minDate={form.values.distributionDate}/>
                        </Group>
                        <TextInput label={"Titre"} {...form.getInputProps("title")}/>
                        <TextEditor editor={editor}/>
                        <Center>
                            <Button onClick={nextStep}>Prochaine Étape</Button>
                        </Center>
                    </Stepper.Step>
                    <Stepper.Step label="Questions">
                        <Flex>
                            <NumberInput label={"Nombre de Questions"} value={amountQuestions} onChange={setAmountQuestions}/>
                        </Flex>
                        {Array.from({length:amountQuestions}).map((_,i)=><QuestionCard key={i} index={i} form={form} {...form.getInputProps(`questions.${i}`)} />)}
                        <Group position="center" mt="xl">
                            <Button variant="default" onClick={prevStep}>Retour</Button>
                            <Button type='submit'>Créer</Button>
                        </Group>
                    </Stepper.Step>
                </Stepper>
            </form>
    )
}

function QuestionCard({form,index,onChange}){
    const [amountChoices,setAmountChoices]= useState(1);

    return (
        <>
            <Paper p={"md"} m={"lg"}>
                <Textarea label="Question" name='title' {...form.getInputProps(`questions.${index}.title`)} />
                <Radio.Group label="Type de réponse" name={`type${index}`} {...form.getInputProps(`questions.${index}.type`)}>
                    <Radio value="text" label="Texte"/>
                    <Radio value="bool" label="Oui ou non"/>
                    <Radio value="choice" label="Choix de Réponse"/>
                </Radio.Group>
                {
                form.values.questions[index]?.type === "choice" ?<><Checkbox label="Choix multiples" checked={form.values.questions[index].multiple} onChange={(e)=>onChange({...form.values.questions[index],multiple:!form.values.questions[index].multiple})}/> 
                <Flex align={"center"} gap={"lg"}><Text size={"lg"} ta={"center"}>Nombre de choix</Text><NumberInput value={amountChoices} onChange={setAmountChoices}/>  </Flex>
                    <Table m={"lg"}>
                        <tbody>    
                        {Array.from({length:amountChoices}).map((_,i)=><ChoiceCard key={index+'from'+i} form={form} formIndex={index} choiceIndex={i}/>)  }
                        </tbody>
                    </Table>
                </>
                : null
                }
            </Paper>
        </>
    )
}
function ChoiceCard({form,formIndex,choiceIndex}){
    return(
        <tr>
            <td>
            <TextInput  {...form.getInputProps(`questions.${formIndex}.choices.${choiceIndex}`)}/>
            </td>
        </tr>
    )
}
function TextEditor({editor}){
    return <RichTextEditor h={300} m={"lg"} editor={editor}>
                    <RichTextEditor.Toolbar sticky stickyOffset={60}>
                        <RichTextEditor.ControlsGroup>
                            <RichTextEditor.Bold />
                            <RichTextEditor.Italic />
                            <RichTextEditor.Underline />
                            <RichTextEditor.Strikethrough />
                            <RichTextEditor.ClearFormatting />
                            <RichTextEditor.Highlight />
                            <RichTextEditor.Code />
                        </RichTextEditor.ControlsGroup>
                        <RichTextEditor.ControlsGroup>
                            <RichTextEditor.H1 />
                            <RichTextEditor.H2 />
                            <RichTextEditor.H3 />
                            <RichTextEditor.H4 />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                            <RichTextEditor.Blockquote />
                            <RichTextEditor.Hr />
                            <RichTextEditor.BulletList />
                            <RichTextEditor.OrderedList />
                            <RichTextEditor.Subscript />
                            <RichTextEditor.Superscript />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                            <RichTextEditor.Link />
                            <RichTextEditor.Unlink />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                            <RichTextEditor.AlignLeft />
                            <RichTextEditor.AlignCenter />
                            <RichTextEditor.AlignJustify />
                            <RichTextEditor.AlignRight />
                        </RichTextEditor.ControlsGroup>
                    </RichTextEditor.Toolbar>
                    <RichTextEditor.Content />

                </RichTextEditor>
}