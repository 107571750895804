import { Card,Text, Group } from '@mantine/core'
import React from 'react'

export default function AnswerHeader({data,questionIndex}) {
    const responses = data.filter(r=> r.responses[questionIndex]!==undefined).map(r => r.responses[questionIndex]);
  return (
    <Card my={"lg"}>
      <Card.Section>
        <Group grow m={"md"}>
          <Text>Nombre de réponse : {responses.length}</Text>
          <Text>Réponse en attente : {data.length - responses.length}</Text>
        </Group>
      </Card.Section>
      <Card.Section >
        <Group grow m={"md"}>
          {Object.entries(countOccurrences(responses)).map(([key,value])=> <Text key={key}>{key} : {value}</Text>)}
        </Group>
      </Card.Section>

    </Card>
  )
}

function countOccurrences(arr) {
  return arr.reduce((acc, val) => {
      acc[val] = (acc[val] || 0) + 1;
      return acc;
  }, {});
}