import React from 'react'
import { Card, Grid, Text, UnstyledButton,Modal,Button,Radio,Container,Stack,TextInput,Flex,Select } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconCurrencyDollar, IconLink, IconTrash } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { getAnswerLink, getUser } from '../connections/requests';
import { createOnePayment,pullMemberFromEvent } from '../connections/requests';
import { useDisclosure,useClipboard } from '@mantine/hooks';
import { DatePicker } from '@mantine/dates';
import { useQuery } from 'react-query';
import { showNotification } from '@mantine/notifications';


function SetToPayment({member,event}){

    const form = useForm(
        { initialValues: 
        { member:member , worker: getUser() ,event:event, amount: 0, type: "activité",description:"",status:"waiting",timeStamp:new Date()}
        });
    return (
        <form onSubmit={form.onSubmit((values) => {createOnePayment(values).then(form.reset)})}>

        <Container py={35} px={50}>
            <Stack align='center'>
                <Card mb={34} w={"14rem"}  >
                    <Text align='center' size={"xl"} weight={700}>Ajouter un paiement</Text>
                </Card>
            </Stack>
            <Stack>
                <Flex align={"center"} gap={"lg"}>
                    <TextInput disabled  placeholder=""  {...form.getInputProps('member')} />
                </Flex>
                <Radio.Group name="medium"  label="Méthode de paiement"
                        {...form.getInputProps('medium')}>
                    <Radio color='green' value="cash" label="Comptant"/>
                    <Radio color='blue' value="cheque" label="Chèque"/>
                    <Radio color='orange' value="transaction" label="Virement"/>
                    <Radio color='violet' value="zeffy" label="Zeffy"/>
                </Radio.Group>
                <TextInput label="Montant"  {...form.getInputProps('amount')} />
                <TextInput label="Description et notes"  {...form.getInputProps("description")}/>
                <Radio.Group name="status"  label="Status"
                    {...form.getInputProps('status')}>
                <Radio color='orange' value="waiting" label="En Attente"/>
                <Radio color='green' value="received" label="Reçu"/>
                </Radio.Group>
                <DatePicker
                    inputFormat='DD MMMM YYYY'
                    labelFormat='DD/MMMM/YYYY'
                    locale='fr-ca'
                    label="Date"
                    allowFreeInput
                    clearable={false}
                    {...form.getInputProps('timeStamp')}
                />
                <Button type='submit'>Soumettre</Button>
            </Stack>
        </Container >
        </form>
    )
}

export default function EventMemberRow({member,event,paid,form}) {
    const answerLinkQuery = useQuery(["answer",form,member._id],getAnswerLink)
    const [opened,handlers] = useDisclosure(false)
    return (
        <>
        <Modal opened={opened} onClose={()=> handlers.toggle()}>
        <SetToPayment member={member._id} event={event}/>
        </Modal>
            <Card withBorder px={"xl"}>
                <Grid justify={"space-evenly"} >
                    <Grid.Col span={7}>
                    <Text component={Link} to={`/auth/member/${member._id}`} >{`${member?.prenom}  ${member?.nom}`}</Text>
                    </Grid.Col>
                    <Grid.Col span={1}>
                    <UnstyledButton onClick={() => handlers.open()} > {paid ? <IconCurrencyDollar color='green' /> : <IconCurrencyDollar color='red' />}
                    </UnstyledButton>
                    </Grid.Col>
                    <Grid.Col span={1}>
                    <UnstyledButton onClick={() => {pullMemberFromEvent(member._id, event);}}><IconTrash color='red' /></UnstyledButton>
                    </Grid.Col>
                    <Grid.Col span={1}>
                        <GetAnswerButton/>
                    </Grid.Col>
                </Grid>
            </Card>
        </>
    )
    function GetAnswerButton(){
        const clipboard = useClipboard()
        if(answerLinkQuery.status === "error")
        return(
            <UnstyledButton>
                <IconLink color='blue'/>test
            </UnstyledButton>
        )
        if(answerLinkQuery.data === false)
            return(
            <UnstyledButton >
                <IconLink color='orange'/>
            </UnstyledButton>
            )
        return (
            <UnstyledButton onClick={()=>{clipboard.copy(`https://data.adsmqam.org/hermes/${answerLinkQuery.data}`);showNotification({message:"Le lien est collé dans le presse-papier"})}}>
                <IconLink color='blue'/>
            </UnstyledButton>
        )
    }

}
