import { useState, React } from 'react';
import {
    createStyles,
    Table,
    ScrollArea,
    UnstyledButton,
    Group,
    Text,
    Center,
    TextInput,
    Modal,
    Button,
    Title,
    Flex
} from '@mantine/core';
import { IconSelector, IconChevronDown, IconChevronUp, IconSearch } from '@tabler/icons-react';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import {  getAnswerList } from '../connections/requests';
import AnswerHeader from './AnswerHeader';


const useStyles = createStyles((theme) => ({
    th: {
        padding: '0 !important',
    },

    control: {
        width: '100%',
        padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,

        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        },
    },

    icon: {
        width: 21,
        height: 21,
        borderRadius: 21,
    },
}));


function Th({ children, reversed, sorted, onSort }) {
    const { classes } = useStyles();
    const Icon = sorted ? (reversed ? IconChevronUp : IconChevronDown) : IconSelector;
    return (
        <th className={classes.th}>
            <UnstyledButton onClick={onSort} className={classes.control}>
                <Group position="apart">
                    <Text weight={500} size="sm">
                        {children}
                    </Text>
                    <Center className={classes.icon}>
                        <Icon size={14} stroke={1.5} />
                    </Center>
                </Group>
            </UnstyledButton>
        </th>
    );
}



function sortData(
    data,
    payload
) {
    const { sortBy } = payload;

    if (!sortBy) {

        return data;
    }

return data;
}


export function SingleAnswerTable({questionIndex}) {
    let {id} = useParams();
    const {data,status} = useQuery(['form',id],getAnswerList);
    const [search, setSearch] = useState('');
    const [sortedData, setSortedData] = useState(data);
    const [sortBy, setSortBy] = useState(null);
    const [answerModal,setOpenedModal] = useState(-1);
    const [reverseSortDirection, setReverseSortDirection] = useState(false);

    if(status === "loading") return <div>loading</div>
    const setSorting = (field) => {
        const reversed = field === sortBy ? !reverseSortDirection : false;
        setReverseSortDirection(reversed);
        setSortBy(field);
        setSortedData(sortData(data, { sortBy: field, reversed, search }));
    };

    const handleSearchChange = (event) => {
        const { value } = event.currentTarget;
        setSearch(value);
        setSortedData(sortData(data, { sortBy, reversed: reverseSortDirection, search: value }));
    };

    const rows = data.responses.length>=1 ? data.responses.map((row,index) => (
        <tr key={row._id}>
            <td><Link to={ row._id} style={{ textDecoration: 'none' }}>{row?.member?.fullName}</Link></td>
            <td>{row.filled ?<Text>{row.responses[questionIndex]}</Text>:<Button disabled>Réponses</Button>}</td>
            <td>{row.filled?"rempli":"pas rempli"}</td>
            <Modal title="Réponses" opened={answerModal === index} onClose={()=>setOpenedModal(-1)}>
                {row.responses.map((r,index) => <Flex gap={"md"} align={"center"}><Title order={3}>{data.questions[index].title}:</Title><Text>{r}</Text></Flex>)}
            </Modal>
        </tr >
    )):[<tr></tr>];

    return (
        <div>
            <AnswerHeader data={data.responses} questionIndex={questionIndex}/>
            <ScrollArea>

                <Table
                    horizontalSpacing="md"
                    verticalSpacing="xs"
                    sx={{ tableLayout: 'fixed', minWidth: 700 }}
                >
                    <thead>
                        <tr>
                            <Th
                                sorted={sortBy === 'title'}
                                reversed={reverseSortDirection}
                                onSort={() => setSorting('title')}
                            >
                                Répondant
                            </Th>
                            <th
                                sorted={sortBy === 'location'}
                                reversed={reverseSortDirection}
                                onSort={() => setSorting('event')}
                            >
                                Réponses
                            </th>
                            <th>
                                État de la réponse
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows.length > 0 ? (
                            rows
                        ) : (
                            <tr>
                                <td colSpan={Object.keys(data[0]).length}>
                                    <Text weight={500} align="center">
                                        Nothing found
                                    </Text>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </ScrollArea>
        </div>
    );
}
