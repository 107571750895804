import React,{useState} from 'react'
import { Container, Title,Select, Paper, Card } from '@mantine/core';
import {Route,Routes, useParams} from "react-router-dom";
import { Tabs,Text } from '@mantine/core';
import { useQuery } from 'react-query';
import { fetchPaymentsFromMonth,fetchAllPayments, fetchPaymentsBetween } from '../connections/requests';
import { PaymentTable } from '../payment/PaymentTable';
import { PaymentTableAll } from '../payment/PaymentTableAll';
import { PaymentTableBetween } from '../payment/PaymentTableBetween';
import { DatePicker } from '@mantine/dates';
import JobMonthCard from '../job/JobMonthCard';

const months= [ 
{ value:'0',label:"Janvier"},
{ value: '1', label: 'Février' },
{ value: '2', label: 'Mars' },
{ value: '3', label: 'Avril' },
{ value: '4', label: 'Mai' },
{ value: '5', label: 'Juin' },
{ value: '6', label: 'Juillet' },
{ value: '7', label: 'Août' },
{ value: '8', label: 'Septembre' },
{ value: '9', label: 'Octobre' },
{ value: '10', label: 'Novembre' },
{ value: '11', label: 'Décembre' }
];

export default function JobPage() {

    return (
        <Container>
            <Card my={"lg"}>
                <Card.Section my={"lg"} bg={"blue"}>
                    <Title color='white' order={1} ta={"center"}>Bénévolat</Title>
                </Card.Section>

        <Routes>
            <Route exact path="" element={<MainPage />} />
            <Route path=":job" element={<JobMonthCard id={useParams()["*"]}/>} />
        </Routes>
        </Card>
        </Container>
    )
}
function MainPage(){
    return(
        <Container size="xl" >
        <Tabs defaultValue="Rendus mensuels" >
            <Tabs.List grow >
                <Tabs.Tab value="Liste"><Text size={"xl"}>Liste</Text></Tabs.Tab>
                <Tabs.Tab value="Rendus mensuels"><Text size={"xl"}>Transactions mensuelles</Text></Tabs.Tab>
                <Tabs.Tab value="Rendus spécifiques"><Text size={"xl"}>Transactions spécifiques</Text></Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="Liste" pt="xs">
                <AllPage/>
            </Tabs.Panel>
            <Tabs.Panel value="Rendus mensuels" pt="xs">
                <MonthlyPage/>
            </Tabs.Panel>
            <Tabs.Panel value="Rendus spécifiques" pt="xs">
                <BetweenPage/>
            </Tabs.Panel>
        </Tabs>
        </Container>
        )
}
function AllPage(){
    const {data,status} = useQuery('payment',fetchAllPayments);
    if(status==='success')return(        
    <Container>
        <PaymentTableAll data={data}/>
    </Container>)
    return <div>null</div>
}
function MonthlyPage(){
    let [monthID,setMonthID] = useState(new Date().getMonth().toString());
    const {data,status} = useQuery(['payment',monthID],fetchPaymentsFromMonth);
    if (status==='success')return(
        <Container>
            <Paper p={'lg'}>
            <Select label="Mois" defaultValue={monthID} data={months} value={monthID} onChange={setMonthID} />
            <PaymentTable updater={(e)=> {e++}} month={monthID} data={data}/>
            </Paper>
        </Container>
    );
    return <div>null</div>
}
function BetweenPage(){
    const [startingDate, setStarting] = useState(
        new Date(),
    );
    const [endingDate, setEnding] = useState(
        new Date(),
    );
    const {data,status} = useQuery(['payment',[startingDate,endingDate]],fetchPaymentsBetween);
    if (status==='success')return(
        <Container>
            <Paper p={'lg'}>
            <DatePicker
    label="Dates"
    placeholder="Choisir des dates"
    value={startingDate}
    onChange={setStarting}
    />
    <DatePicker
        label="Dates"
        placeholder="Choisir des dates"
        value={endingDate}
        onChange={setEnding}
    />
            <PaymentTableBetween  data={data}/>
            </Paper>
        </Container>
    );
    return <div>null</div>
}
